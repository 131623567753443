// blogsData.js
const blogs = [
    {
      id: 2,
      title: "Education Inflation - a couples primary battle !!",
      content: "I am the first in my family to earn an MBA, a dream my father had for me (a middle class big dream). I dedicated two sincere years to my MBA, guided by a group of exceptional professors who mentored me throughout....",
      fullContent: `⚠️ Caution : A long read 
 
I am the first in my family to earn an MBA, a dream my father had for me (a middle class big dream). I dedicated two sincere years to my MBA, guided by a group of exceptional professors who mentored me throughout.
My first job was through campus recruitment. I was among the first set of Business Trainees from business school to be selected by a reputable life insurance company. I admired my superior - his aura, his charm, his energy - and I fell in love with the idea of becoming like him. Observing him from afar, I learned a lot. Within a year, people began referring to me as Junior (his name). A year later, I had the opportunity to work directly under him. This close collaboration helped me transform into a better version of myself.
Going back in time - On the first day of induction, after the team interaction, he was wondering why I was sitting with the Business trainees and not the Management trainees. He checked with the HR, suggesting that this individual had something special and should be classified as a Management trainee, not a Business trainee. The HR explained to us that the company's hiring standard is not about talent or ability, but rather the college from which the individual is hired.
 
Category A includes IIMs and top institutions with multiple branches 
Category B comprises well-established brands such as BIMs and direct universities.
Category C consists of B-schools affiliated with, or deemed to be, universities.
Category D is made up of standard institutions.
 
1.) An individual from Category A starts at Level 6 as Management Trainees, a level where employees have typically worked in the company for over 15 years.
2.) An individual from Category B starts at Level 4 or 5.
3.) An individual from Category C starts at Level 3 joins as Business Trainees. 
4.) Finally, an individual from Category D starts at Level 2.
 
As a first-generation graduate it's not my fault, the challenges faced are not personal failures, but rather a result of the institution and its structures. After encountering difficulties, I developed a strong dislike for HR practices. This experience ignited a fire within me to prove myself from scratch and grow to where I am today. The journey was not easy—it was filled with early morning to late night passionate works, torn on the pathway, big politics playing around and behind me, greed of peers, egos of people near and far - you have to cross these with self courage.
I proved myself to the person who believed in me. Despite being placed in the wrong role initially, I flourished and found my true calling, which included the opportunity to work under him - a memory I cherish. After my father and three professors, he was the next person to have faith in me, followed by another silent supporter MR who has trusted me.
If you look into the gaps - more or less MBA from all these institutions comes with different costs and exposure. All these are merely an return of investment.
 (EMPLOYMENT IS SOMETHING WHICH IS RETURN ON EDUCATIONAL INVESTMENT)
So now you understand the politics behind education.
The secret recipe is this - not all individuals from IIMs are inherently talented, but the strength of their network and training enables them to ascend to top levels quickly, something that a typical B-school doesn't adopt / have / teach. 
I always advise the next generation that it's your interest and passion that propels you and opens up new opportunities. Therefore, think big and dream big. The world may be against you, even try to tear you down, but having just one person who believes in you can transform you and inspire you to work harder. Believe in yourself!
This inequality in education in India is a crime(everyone has to have equal education - like some developed countries like USA - Schools the basement has to be free) but still we have to live in the reality and upgrade ourself to guide the next generation and their parents. 
Rich get richer and poor get poorer is a old saying. Rich gets Richer, poor gets better with Financial Literacy and not falling into the economic commercial traps.
Money is the key to unlocking potential. Start saving for education, the most powerful tool to transform the world !
As a parent, providing education is akin to a king handing over his sword 🗡️ and transferring his kingdom to his son. Education is the most potent tool that can refine both you and those around you.
As soon as you begin your journey as parents, it's crucial to start planning for your child's education.
Did you know the current education inflation rate in India is 11%? This suggests that a course costing ₹20 lakhs today will escalate to ₹1.8 crore in 20 years - quite shocking, right?
So, what's the solution? One effective strategy is to invest in mutual funds and insurance. Consider a premier business school: in 2003, a two-year MBA program cost 3 lakh rupees. By 2013, the cost increased to 17 lakh rupees, and by 2023, it reached 25 lakh rupees. This indicates an annual inflation rate of 11% over 20 years. If this trend persists, the same program will cost 41 lakh rupees by 2028.
Therefore, to overcome this inflation, mutual funds, particularly equity mutual funds, are your best bet. They yield an average annual return of 12%. Moreover, mutual funds offer benefits like returns that outpace inflation, diversification, professional expertise, flexibility, and liquidity.
 
Are you a first-time Mutual Fund investor who's apprehensive about taking risks ?
You might want to consider investing in short-term, low-cost ULIPs which provide flexibility and peace of mind with,

- Unlimited Free fund switches between liquid, debt, balanced, and equity funds
- 80 C Tax benefit up to 1.5 Lakhs
- Sec 10(10 D) Tax-free returns for premium not exceeding 2.5 Lakh investment per year
- Life Cover as investment protection, option to increase coverage up to 1.5 Crores with investment
- Return of all charges via Loyalty additions for long-term stay
- Portfolio auto rebalancing, ATS(Automatic Transfer Strategy) and some ULIPs has SW(Systematic Withdrawal) inbuilt
- Unlimited Top up option (not exceeding 2.5 Lakhs per annum during premium payment term for a tax free returns)
Unlike Mutual funds, low-cost ULIPs has many features which are the best way for a first time investor to save for your children's future and plan for retirement wisely.

Unfortunately, my father passed away on August 2nd, 2017 and was not able to witness my graduation on September 2nd, 2017 during my initial start of career. 
 
Just a school of thought - what if my father has passed away before me starting an MBA ?? 
I wouldn't have been here today and you wouldn't have been reading this article today. 

To avoid these kind of unfortunate events. If as a father you has taken a Life Cover of 2 Crs. with focused investments like Mutual funds / ULIPs - your family is financially secure and the coverage replaces a father's income incase of mishap, and the Goals of the family continues without a break.

Traditional plans offer you this with WAIVER OF PREMIUM which gives peace of mind and whatever happens to life, the financial goals are completely taken care off.
EG : RAMU is 35, plans to Invest Rs. 5,00,000/- per annum for 8 Years for his 2 years old Kid. And plans a lumpsum of Rs. 85,00,000/- at kids age of 17 with policy term 15 Years. He has took an add-on rider called as WAIVER OF PREMIUM (in some plans the feature is inbuilt thereby slightly reducing the returns).
After paying 3 premiums - RAMU has passed away.
What happens now ?
Death benefit (*10 X (Rs. 50,00,000/-)) is paid to nominee
Future premiums are waived off
Kid receives maturity at age of 17 for his graduation
Now goals are met. Wife has option to take death benefit as lumpsum / Income which can take care of kids tuition fees and lumpsum for kids graduation into a good institute is also taken care off. Mr. Ramu is not around but still took care of kids education who is the future.

Planning finance is an art. And I'm on a journey giving back something to the society. And mostly my portfolio creation will always start with an assignment for couples, reviews on financial spending's & lifestyle, making collective decisions for the transformation that they wanted to see and then the investment journey begins.

Making Finance Easy
To know more and looking for a personal finance coach for shaping your finances aligned to your all Life Goals message me on LinkedIn or Instagram.
https://shorturl.at/blnCT`,
      image: "https://media.licdn.com/dms/image/v2/D5612AQGSrNhpQU_Xog/article-cover_image-shrink_423_752/article-cover_image-shrink_423_752/0/1713851803988?e=1729123200&v=beta&t=58EZNKSNfYd3cno0PQ5KZHJtNCiMVhr_cI1pxEoXLYk",
      author: "-Rajarathinam S K",
      date: "April 23, 2024"
    },
    {
      id: 3,
      title: "The Wake-Up Call : Understanding the Gaps in your Corporate Mediclaim Insurance",
      content: "Health insurance is a vital safeguard against medical emergencies, offering numerous benefits that secure your financial well-being and access to quality healthcare...",
      fullContent: `Health insurance is a vital safeguard against medical emergencies, offering numerous benefits that secure your financial well-being and access to quality healthcare.

Facts of Corporate Health Insurance : The Limitations
Company-Linked: Corporate health insurance is tied to the company, not the individual.
Coverage Termination: When leaving the company, coverage ends.
Higher Premiums: Switching jobs or retiring can lead to higher premiums.
Inadequate Coverage: Corporate plans may not sufficiently cover family needs.
Retention Tool: Companies use group health insurance to retain employees.
Tax Incentives: Employers receive tax benefits.
Top Talent Attraction: Group insurance helps attract skilled employees.
Premium Calculation: Premiums are based on factors like average age, job type, coverage extent, sum insured, add-ons, and claim history.

Why Group Health Insurance May Be Costly:
Average Age: Older workforces pay higher premiums.
Job Types: High-risk occupations increase costs.
Coverage Extent: More employees covered means higher premiums.
Sum Insured: Larger coverage amounts result in higher costs.
Add-Ons: Optional coverages (e.g., dental) add to expenses.
Claim History: Frequent claims can raise future premiums.

How Companies Use Group Health Insurance as a Tool :
Tax Incentives: Employers can deduct premiums from taxes.
Attraction and Retention: Group insurance helps retain top talent.
Dig deep down and work around it.

Don't Rely Solely on Corporate Health Insurance:
 Consider supplementing your corporate health insurance with individual health insurance coverage
Ensure your family's protection against medical expenses, even after you leave your company or retire
Review your health insurance needs and adjust your coverage accordingly to ensure adequate protection for your family

By purchasing individual health insurance in India, you can:
Attain financial stability during medical emergencies
Protect your loved ones from financial burdens
Access the best medical treatment available
Preserve your savings from medical expenses
Manage treatment costs for lifestyle disorders
Supplement existing insurance coverage when it's inadequate
Enjoy tax benefits under Section 80D

Tax Benefits:
Under the old tax regime,
Every individual or HUF can claim a deduction for medical insurance premiums paid in the financial year under Section 80D deduction available for top-up health plans and critical illness plans over and above the Rs 1.5 lakh limit deductions claimed under Section 80C
Deduction Limits (up to 1,00,000) :
Up to ₹25,000 for self, spouse, dependent children, or parents (non-senior citizens)
Up to ₹50,000 if parents below 60 years
Up to ₹75,000 if parents above 60 years

Conclusion : The Strategy 
In India, health insurance is considered a necessity due to the increasing costs of healthcare and changes in lifestyle. It is essential to supplement corporate health insurance with individual coverage to ensure comprehensive protection. 
Health insurance offers additional benefits such as regular health monitoring with reward points, cashless coverage, no room rent / ICU capping, increase up to 6X no claim bonus, instant cover, pregnancy cover, access to top medical facilities, and coverage for critical illnesses.
For employers, group health insurance is a valuable tool to attract and retain top talent, while also enjoying tax benefits. From an employee's perspective, having a company-sponsored health insurance plan can be beneficial, especially if it covers the costlier premiums of older parents. However, relying solely on the company's plan may limit job market exploration. To address this, it is recommended to follow the following strategy:
Start an individual health insurance plan alongside the group insurance. This individual plan will cover pre-existing diseases after a waiting period of 2-4 years, depending on the insurer.
Maintain the group insurance as a safety net during the waiting period.
After 2-4 years, the individual cover will also include coverage for pre-existing diseases, providing comprehensive coverage.
If the individual policy has permanent exclusions for critical illnesses or diseases, it is advisable to keep the group Mediclaim to cover those costs.
It is important to carefully consider these parameters and discuss them with your Insurance or finance advisor, HR and corporate Mediclaim team while enrolling to optimize costs. Waiting until it's too late is not advisable. Investing in health insurance is crucial to secure your financial well-being and ensure access to quality healthcare.`,
      image: "https://media.licdn.com/dms/image/v2/D5612AQHx4r7sQYCD6g/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1719231899838?e=1729123200&v=beta&t=Zn0FbPysKGn4zZNQ-zkhrn4NUfYASt8BaXYkAqKX5uk",
      author: "RajaRathinam S K",
      date: "June 24, 2024"
    },
    {
        id: 4,
        title: "MONEY HEALTH - a timeline",
        content: "Mental Health Health encompasses physical, mental, emotional, and social well-being, and these aspects are interconnected...",
        fullContent: `Mental Health
Health encompasses physical, mental, emotional, and social well-being, and these aspects are interconnected.
In today's world, stress, worry, and tension are the leading culprits behind illness and disease.
When these factors persist for a prolonged period, they can give rise to various mental challenges, which in turn can manifest as physical and emotional ailments. Therefore, it is crucial to prioritize and take care of one's mental health.
Building / Practicing Healthy behaviors
To maintain good health, there are certain practices that should be embraced.
Well-balanced diet,
Regular exercise,
Hygienic habits such as keeping your room, bed, and pillows clean, living in a clean environment,
Drinking water within the pH range of 6.5-8.0 can boost immunity and equip individuals to combat most diseases.
All these looks like easy ways to accomplish but it’s not, including me.
Let’s practice!!
As we GROW – we STOP learning
Engaging in various activities is also beneficial for maintaining good health.
As we grow, it is important to continue learning and stimulating our brains.
Playing an instrument,
Participating in indoor or outdoor games, and
Reading books, comics, and articles
All these provide the necessary exercise for the brain, thereby improving overall health. By adopting healthy behaviors, individuals can enhance their outlook on life and contribute to their longevity and success. A healthy body and mind have the capacity to achieve happiness and accomplish.
Health mind-set brings a Wealthy mind-set
Sometimes, we become so fixated on achieving optimal health that we invest significant amounts of money in diets and health programs, only to end up feeling miserable.
However, the key lies in staying attuned to the seasons, eating consciously, and being mindful of how we treat our bodies. By doing so, things will naturally fall into place, and we will feel more energized to earn a little extra to sustain ourselves.
MONEY is the key to Health
Many believe that wealth and health go hand in hand, and this sentiment rings true in various aspects.
Financial wealth can offer
Individuals
o   A sense of security & stability and reduces stress
o   To fulfil their needs and desires
o   Freedom to make choices
o   Doors to opportunities and experiences
 §  Pursue higher education (leading to improved career prospects and personal development)
 §  The joy and fulfilment, such as travel or hobbies
o   To afford quality healthcare
 §  Eating quality food
 §  Having a routine for maintaining physics
 >         Networking and engaging in various forums
 >        Going for Exercise, Swimming, Badminton / Golf
 §  Legacy Planning with proper Term Coverage
 >         Multiply their family generational wealth
 §  Taking high end comprehensive insurance plans
 >         Affording modern & advanced treatments.
Ultimately MONEY leads to a better quality of life.
So money is “energy” which you attract through your abilities and way of being. Once your mind is healthy and you are emotionally stable, you'd be able to handle money properly.
Conclusion :
It is important to acknowledge that wealth alone does not guarantee happiness or good health. Chasing wealth we lose our health. Striking a balance between health and wealth is crucial. Without good health, wealth may not be as enjoyable or beneficial. Conversely, without financial stability, maintaining good health can be challenging. Prioritizing both health and wealth is essential to ensure they complement each other and contribute to overall well-being and happiness.
Its NOT about wealth or Health – it’s about TIME which you can trade for HEALTH & WEALTH.
Wealth takes time to build !! 
Health takes time to build !!
With proper financial planning – you can gain financial literacy that gives you confidence.
With proper exercise & diet – you can gain healthy mind-set that gives you confidence.
What really matters is the TIME....
Dedicate some time for taking care of your health (mental & physical) and personal finance.`,
        image: "https://media.licdn.com/dms/image/D5612AQF6XyM_XA4bVQ/article-cover_image-shrink_720_1280/0/1721196143712?e=1729123200&v=beta&t=SzfgvQCS24O6Om7qsUDQDYd61iX3dQLKlFSg-T_MQho",
        author: "RajaRathinam S K",
        date: "May 29, 2024"
      },
      {
        id: 5,
        title: "MENT to Retirement",
        content: "Working gives our life meaning and purpose, which is why many of us do it in addition to receiving a paycheck...",
        fullContent: `Working gives our life meaning and purpose, which is why many of us do it in addition to receiving a paycheck. In addition to providing goals and a cause to leave the house each day, your job can help you feel important, productive, and helpful. Your immune system and brain function better when you have a purpose in life. It also satisfies certain biological demands.
Searching for new meaning-filled pursuits that brighten your life after retirement is crucial. Retiring to something, rather than just from something, might be beneficial in this regard. Examples of such pursuits include continued education, a meaningful hobby, and volunteer work.
 
Backdrop
Life expectancy is on the rise, resulting in individuals living longer after reaching retirement age than ever before, assuming retirement ages remain unchanged. The average life expectancy at the commonly accepted retirement age of 65 has significantly increased over the years.
However, due to declining birth rates, there are fewer young people to compensate for the departure of the elderly from the workforce. Consequently, there will be a shift in the balance between employed and unemployed individuals.
One of the most challenging aspects of preparing for retirement is envisioning life in one's seventies. Many individuals become overwhelmed by the uncertainty of the future and end up not saving anything at all.
Fortunately, planning for retirement does not have to be excessively burdensome. However, it is crucial to have a flexible roadmap that can adapt over time to ensure you stay on the right path.
To begin, it is crucial to envision what your life could be like during retirement. Take a moment to sit down with a pen and paper, and jot down your retirement aspirations.
Next, consider the potential costs associated with your retirement plans. It is uncertain how prices will fluctuate in the future, especially due to inflation in recent years.
While it is always advisable to start saving early – even setting aside 500 per month in your 20s can be beneficial – it is acceptable to prioritize immediate financial needs before focusing on retirement in your late 40s or early 50s. However, it is important not to delay beyond that point, as you will require time for your retirement funds to grow. The longer you wait, the more you will need to contribute annually, making the task considerably more challenging.
 
Retirement challenges to consider
•           No Retirement Strategy
Many individuals approach retirement savings haphazardly. Without a clear plan, it's easy to become overwhelmed and fall short of your financial goals. Compound interest can have a significant impact.
•           Commencing Savings Too Late
Beginning to save early allows your money more time to grow rapidly. Starting late may result in a rush to accumulate a sufficient corpus in a shorter period.
•           Debt Accumulation
Debt obligations can put a strain on your retirement income. High-interest credit card debt or large monthly loan payments may reduce the amount you can save for retirement.
•           Market Volatility and Unexpected Expenses
Stock market fluctuations can diminish your retirement assets, while unforeseen medical expenses or emergencies can disrupt your plans.
•           Inflation Diminishing Purchasing Power
Inflation continuously erodes the purchasing power of money. A fixed income may eventually prove inadequate to sustain your desired retirement lifestyle.

Addressing retirement challenges requires planning, discipline, and possibly professional guidance. Remember, securing a comfortable retirement is a journey, not a sprint. Plan ahead, start early, and adapt as needed.
Instruments to focus in your early 20’s to 30’s which people like me have missed,
-          If you are employed,
o  Opt for Voluntary EPF contribution
§  Apart from your Employer contribution, 12% of basic can be moved aside for your future as voluntary contribution
§  It helps you in saving tax
§  It increases at a compounding interest of 8.1% per annum
Never withdraw this amount. My first company HR Jaisel Mangan during my exit interview told me “Raj whatever the situation is NEVER EVER touch your EPF account balance” – Now I recall her after years with the real life experience gained now.

o Opt for NPS employer contribution (if your employer allows)
§  10% of your basic + DA can be allocated which grows in the market
These are treasure’s you can build for your future YOU.

-          If you are Businessman,
o   Start a NPS account
§  Under Sec 80 CCD you can save tax upto 50 K per annum
§  Your investment grows in the market
Instruments to focus in your 30’s to early 40’s
This is the phase where you will be more focused on building a corpus for your
-          Kid’s Education
-          First dream long vacation
-          10th Anniversary
-          First dream house
-          Exploring career opportunities
So when commitments are getting overloaded in this phase. You ensure you diversify your investments into those goals ensuring all those dreams are taken care with a proper TERM LIFE COVERAGE inline to your expenses / commitments / income. Keep upgrading it every 3 years once.
Try completing all these investments in a mid-horizon when fund outflow stops at age 45. Those investment inflow will come at planned ages to meet your LIFE GOALS.
-          Start focusing on short-term instruments
o   ELSS Mutual Funds
o   ULIP Investments
o   Trading & Equities
Also have a proper Health Insurance for life with individual covers rather than floaters matching the modern treatments and city cost where you live in.
Instruments to focus in your mid 40’s to early 60’s
-          Start focusing on long-term instruments
o   Bonds
o   Insurance investments
o   ETF's 
o   PMS
o   Fixed Deposit / Annuity plans
o   Mutual Funds with 10 years holding
o   Investing in start ups

All these returns will cater to your,
-          Pre retirement planning (an inflow for age 50-60)
-          Retirement planning (a lumpsum from NPS & EPF hits your account at age 60, you add a top up to it or for 65 / 70)
-          Post retirement planning (an inflow till your life + your spouse’s life)
NPS + EPF + Retirement plans settlement corpus adds beauty by leaving a legacy to your kids / grandkids.
In India, it's important to be financially sound from emotional point of view as well. Plan your Life Journey with your investment advisor today. He can help you in forecasting and preparing your Life Goals well inline to your Family needs and wants. 
`,
        image: "https://media.licdn.com/dms/image/D5612AQESfE6jnUW-WA/article-cover_image-shrink_720_1280/0/1716205479217?e=1729123200&v=beta&t=BGOBO7BlawPkvcqzZtwJ2e6aMH3PDrX8rL86z_QzKIw",
        author: "RajaRathinam S K",
        date: "May 20, 2024"
      },
      {
        id: 6,
        title: "Money Mindset : Unlocking your Mind",
        content: "Does MONEY makes you Happier ? I heard many saying : Yes. Of course, as always, there is more to the story...",
        fullContent: `Does MONEY makes you Happier ?
I heard many saying : Yes.

Of course, as always, there is more to the story. Let’s get into the nitty-gritty of money and contentment.

Will Earning a Great Salary Make Us Happier?
Your salary affects your quality of life in many ways. It affects the food you eat, where you live, and the type of car you drive. These changes will make you happier, but only to a point.

"Money helps us meet our basic needs, but research shows that more money doesn't always lead to greater happiness".

When I keep interacting with people and look at people at higher levels too,

It's interesting to note, in any case, that the sum of money people think they got to earn to be happy is very different.

Individuals making 3,00,000 bucks thought they'd ought to increment their yearly compensation to 5,00,000 bucks to be happy. But, those gaining 10,00,000 per year evaluated an annually compensation of 25,00,000 would make them happy. In brief, individuals continuously think they require a small more cash to be Happy. 

The examples discussed above only look at income which sits within the bounds of average earners. Many people want to exceed this and earn millions, living a lavish celebrity lifestyle.

Can you blame them?

But will this make them happier?

Let’s look at other side of the coin to examine these questions in more detail.

Is it thought that Elon Musk, a globally recognized and richest individual, is satisfied with the wealth he has accumulated?

Answer is NO

Our tendency as a society is to imagine that being a big achiever will solve all our problems.

Not so.

People with more prestigious jobs, fancy designations and more money were no happier in their lives.

The notion that you will be satisfied if you chase a money-oriented achievement – like a big promotion or a Crore figure in your Retirement kitty – pushes happiness into the future and always out of reach.

Everyone is trying to live someone else's life / in the future instead of living their own in the present moment which is the eternal truth.
So What Can Make Someone Truly Happier?
Have you watched the movie Thillalangadi which is released in the year 2010.

Jayam Ravi, (Indian Kollywood actor) who keeps changing his Job, in search of KICK ('addiction' - HAPPINESS) which we can call as satisfaction too.

He never finds his KICK from a low paid job to a highly paid job. Accidentally he comes across a child who was suffering from a life threatening disease and costs a lot for the treatment. He approaches a well settled politician who speaks infront of media - he is ready to help people, and they are his life - "he ignores stating he has no money". He mortgages all his fathers property, car, bike and arranges funds for the operation.

After the operation, on seeing the child's SMILE he feels that real KICK, he smiles from inside and touches his heart. When the child asks Jayam Ravi - do you have any dream uncle ? He says no. The child casually says "No problem uncle, on satisfying once own desires, where the kick is ! making others dreams come true is the biggest KICK." He finds his meaning for his life.

We realize after a point in life that real happiness is not in money, its in adding value to someone's life.
So, I doesn't ask you to stop dreaming or doing something for yourself. Its really important. Before you take care of others, you have to take care of yourself and that soul satisfaction will help in extending your happiness.

PREPARE FOR POST-RETIREMENT LIFE NOW
Retirement represents a significant risk to many people’s happiness and sense of self-worth: Since so many people are so identified with their careers and job titles, retirement can take all that away and make them feel totally lost.

That is why mid-career professionals should think about that gear-shifting now. Build a life framework with purpose and meaning and networks outside of the office. That could mean taking up new activities, or repairing old friendships, or volunteering for favorite causes.

“People who have done best in retirement are those who lean into it, and think about their social connections, and rebuild their networks outside of work”.



VALUE - EXPERIENCES OVER THINGS
Give back something to the society with knowledge / time / money you have.

My favourite way to spend money to bring happiness is to spend money on time.

For example : Paying others to do jobs we don’t enjoy around the house, spending money on equipment that makes jobs easier or quicker, like a dishwasher, or even working less because we can afford to can all bring us happiness. Particularly when this money is reinvested into things which bring us joy like hobbies, spending time with loved ones, or films or music.

Compared to the ways of spending money already outlined above, buying things actually is the least efficient way of investing money into our happiness. However, there are ways to ensure that our material purchases are bringing us happiness. We can buy several smaller treats, spend time considering exactly what we want to buy, and also continue to think about the benefit that the item brings us in our day to day life even after we purchase it.

Rather than buying a bigger house / apartment or a nicer car, if you use your money to share experiences with others, that money will get you a better return on happiness.

That might be a vacation or treating your family to a nice dinner.

If you can't spend money, you can allocate your time, interacting with your family for building that personal relationships stronger.

Connect with others outside of your circle - help them or take help from them in working on unknown topics and cause for wellbeing of society and next generation which can add value to your, their and many others life and yield you returns as well as happiness.


DO A STUDY – ON YOURSELF
Doing some self-examination, and figuring out whether you are doing what’s really important to you matters a lot.

At times in busy work schedules we forgot,

- Having lunch at proper time or drinking water to stay hydrated.

- Working out to keep your body and mind boosted to work productive.

- Taking short breaks to give your body and eyes a stretch.

- Give your health a check once in a year and adjust your lifestyle accordingly.

No one is going to take care of you, you have to take care of yourself.


TREASURE [WORK] FRIENDSHIPS
Do not discount all the little daily interactions with the people around you in the office, a large part of our waking lives is spent at work. If you believe that relationships make for a good life, then you need to think about your connections at work.

Those relationships are important to your well-being, because you spend such a large amount of time with them. Add value to them, support them and grow together.

Happiness is not in having or being; it is in doing. Almost every human being could be happier at once if he realized this basic truth and accepted it.

HAPPINESS IS A PERFUME YOU CANNOT POUR ON OTHERS WITHOUT GETTING A FEW DROPS ON YOURSELF.
Spread Happiness !!`,
        image: "https://media.licdn.com/dms/image/v2/D4D12AQFDN_K6yh9AKA/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1714331381641?e=1729123200&v=beta&t=9EqN3WM4gCY64MczNGsoVEEN1WTCvj6jMno3s5QA-oM",
        author: "RajaRathinam S K",
        date: "April 29, 2024"
      },
      {
        id: 7,
        title: "First Experience",
        content: "First experiences always hold a special place in our lives. Consider:First love, First phone, First bike, First car, First job...",
        fullContent: `First experiences always hold a special place in our lives.

Consider:


First love
First phone
First bike
First car
First job
First salary
First kiss
First marriage
When we do something for the first time, we are exploring something new and unknown. We strive to step out of our comfort zone and tackle the unknown. While we may not always succeed, we value the experience gained throughout the process.

I may not engage in new activities every day, but I can adjust my mindset to feel as if I'm experiencing something new each day.

First Salary
After the first day, week, and month of work, when the FIRST SALARY lands in your bank account— that first substantial amount...

How did it make you feel?

What did you do?

Think back to the moment when you received your first paycheck. Despite it possibly being less than market value, the emotional significance is priceless.

My First Salary Story :
I transferred my entire first salary to my father's bank account on the same day I received it. In retrospect, I wish I had withdrawn the money and given it to him in person, capturing the moment. Despite missing this opportunity, I still felt good about myself. It's because of my father's support that I was able to complete my MBA and enter the corporate world. When he called me, he congratulated me, reminding me that this was my reward for my hard work and that I should make this first paycheck memorable. He advised me to spend it wisely, following my heart.

He then returned all the money back to me via NEFT, labelling it as "Rajarathinam's First Salary". Receiving the money from my father's account brought me great happiness.

I transferred 6,000 bucks into my student bank account, which my father had opened during my school days. As a movie enthusiast, I decided to invest this money in a movie card. This budget is set for one year, from June 2016 to May2017. Now, I can't exceed 6,000, including food and parking, for my movie experiences. If the budget is depleted, I'll have to resort to watching movies online. Therefore, I made sure to spend it wisely.

I bought a dress for my family members and made a surprise visit on a working day. The reception I received on that first visit was different. I was cared for differently and introduced to my circles in a new way. This experience made me realize that our job is the identity by which people remember us.

Upon moving to Chennai for work, my company provided first three days of accommodation and then left us to find our own place. This was my first experience searching for a paying guest (PG) house or apartment in the city.

Since I couldn't find a place right away, I stayed with my senior colleagues in their bachelor's flat. I kept my luggage there, but slept on the terrace under the moon and stars, waking up with the sunrise.

Every night, from 7:30 to 9:30 pm, I would go out searching for a PG or house. The local brokers became familiar with me—some even became business partners in my line of work. After a month of searching, I found a roommate from my company who was from IIM. Together, we moved into a two-bedroom house.

Staying in a single room in a family home might have cost more than a PG, but it provided comfort, energy, and confidence to work independently without disturbance of others. This arrangement allowed me to do the things I enjoy, such as listening to music, watching movies, doing dubsmash, singing with karaoke app, ironing clothes, cleaning dishes, organizing my room, preparing my calendar for the next day, and creating posters, presentations, and MIS rollers. I wouldn't have had the same freedom if I had stayed in a PG with food.

I started saving money in the following ways:

1.) Recurring Deposit (RD) - This is a practice my mom introduced to me in 6th grade.

2.) Gold - This started as a scheme suggested by a sales girl to a friend of mine when I took him to buy a silver ring for me during our first year of college.

3.) Mutual Funds (MF) - My senior roommates advised me to invest in a few funds.

The Recurring Deposit is a small amount. Its annual maturity is used to buy an ELSS fund or to fund my travel lust.

Gold is a self-gifting option. I start a new scheme every year after my birthday, and gift myself gold coins on my next birthday.

The Mutual Funds I have not touched for 3 years. They served as an emergency fund when my father passed away and also covered my honeymoon and the career shift risk after marriage.

With time and age my investment is completely diversified now.


With years of experience, I guide the first generation - Bachelors by designing portfolios in unique ways that cater to their goals and needs.

Have a life cover of 50 Lakhs, nominating either the mother or father as the nominee.
Invest in gold (as coins or bars) for personal marriage or emergency expenses.
Make short-term investments in ULIPs or Mutual Funds for marriage funds.
Invest in oneself, which includes purchasing quality clothing, a first blazer, a good pair of both formal and casual shoes, a fitting watch, sunglasses, a quality perfume, and a signature pen.
After marriage, significant changes often occur. It's crucial for couples to plan their finances together as it strengthens their bond and provides mutual support.
Considerations may include:


Childbirth expenses, accounting for any surplus not covered by company medical insurance
Individual health insurance beyond corporate coverage for self, spouse, parents, and in-laws
Increasing life cover with an add-on of 1 Cr. or 1.5 Cr., designating the spouse as the nominee
Gold schemes, from coins to ornaments, for personal gifts or spouse's birthday or anniversary presents
Focusing on long-term investments, which can also serve as an education fund for children
Transitioning from a bike to a scooter
Purchasing your first car
Children's vaccinations
Wealth accumulation
Making travel plans, whether local, domestic, or international

Investment strategies evolve with different life stages:
1.) Single - Personal dreams and ambitions are the focus.

2.) Getting Married - The focus shifts to shared goals and dreams as two families become one.

3.) Married with Kids - The journey of parenthood begins, introducing new responsibilities.

4.) Kids are Grown - It's time to arrange the children's marriages and plan for pre-retirement and retirement life.

5.) Approaching Retirement - It's time to plan for the post-retirement journey, aiming for 30-40 more years of independence.

6.) Happily Retired - With surplus savings, gifts can be bestowed upon children and grandchildren for their future.

These six stages are intertwined, and all your investments should be interconnected. Most of the time, we don't consider investing carefully and regret later in life due to choosing incorrect investment avenues. These poor decisions often result from hasty planning and the overwhelming options presented to us.

To design your first proper portfolio, feel free to text me.

I'd be glad to assist you in all 6 stages of your investment journey.


-Rajarathinam S K
Making Finance Easy by designing Portfolios & sharing excel books for continuous portfolio review, forecasting and bridge gaping the needs for your goals, time to time.



In English Vinglish movie, when Ajith says bye to Shridevi in airport mentioning about her FIRST time in America as,

முதல் தடவை என்றது லைப் ல ஒரே தடவை தா வரும் - ஹொவ் ஸ்பெஷல். சொ தைரியமா, தாராளமா, கொஞ்சம் கூட வெட்கம் படமா, என்ஜோய் பண்ணுங்க.

(Translation : The first time is a once-in-a-lifetime experience - how special ! So be bold, generous, even without a little shy, enjoy it.)`,
        image: "https://media.licdn.com/dms/image/v2/D5612AQH0Or-dMp7YUQ/article-cover_image-shrink_600_2000/article-cover_image-shrink_600_2000/0/1713992126446?e=2147483647&v=beta&t=XZRCOxdn90O4LO39nfrfiDtf-nYkSOvmZDirVs3mP7s",
        author: "RajaRathinam S K",
        date: "April 24, 2024"
      },
      {
        id: 8,
        title: "TIME IS MONEY: the unseen consequences of time theft and the importance of mindful living",
        content: "In today's fast-paced world, time is a valuable commodity that is often taken for granted. With the rise of technology and the internet...",
        fullContent: `In today's fast-paced world, time is a valuable commodity that is often taken for granted. With the rise of technology and the internet, our lives have become increasingly intertwined with digital platforms, making it easier for corporations to capitalize on our time and attention. This article delves into the concept of time theft, how it affects our daily lives, and the consequences of our addiction to social media and technology.


The corporate playbook: how time theft generates revenue
From food delivery apps like Zomato, Swiggy to online grocery shopping platforms like Big Basket, Blink it and Zepto corporations have mastered the art of monetizing our time. They use various tactics to keep us engaged, such as endless scrolling, personalized recommendations, and targeted advertisements. These strategies are designed to keep us hooked, often leading to mindless consumption and a significant waste of time. For instance, a study revealed that the average person spends around 2 hours and 45 minutes on social media daily, which translates to approximately 6 years and 3 months of their lifetime.


The laziness epidemic: how corporations profit from our indolence
Laziness is a lucrative business, and corporations are cashing in on our desire for convenience. With the rise of online shopping, food delivery, and streaming services, we are increasingly relying on technology to cater to our every need. While these services may save us time in the short term, they also contribute to a culture of laziness, where we are less inclined to engage in physical activity, cook our own meals, or even venture outdoors. This sedentary lifestyle has severe consequences on our physical and mental health, leading to a decline in overall well-being.


The social media trap: how YouTube and Social media consume our time and attention
Social media platforms, particularly YouTube, have become an integral part of our daily lives. With algorithms designed to keep us engaged, we often find ourselves mindlessly scrolling through our feeds, watching videos, and comparing our lives to others. This constant stream of information can lead to a sense of FOMO (fear of missing out), anxiety, and depression. Moreover, social media platforms are designed to be addictive, using techniques such as infinite scrolling, notifications, and personalized content to keep us hooked.

 

The consequences of time theft: a loss of presence and cherished relationships
The consequences of time theft are far-reaching and devastating. By sacrificing our time and attention to corporations and social media, we are losing touch with the world around us. We are forgetting to cherish the beautiful relationships we have, the beauty of nature, and the simple joys of life. We are trading our presence in the moment for a virtual existence, where likes, comments, and followers have become the metrics of our self-worth.

 

The importance of traditional shopping experiences
In contrast, traditional shopping experiences, such as visiting local markets, fish markets, and vegetable markets, offer a unique opportunity to develop essential life skills. When we shop at these markets, we interact with shopkeepers, vendors, and fellow customers, teaching us valuable interpersonal skills, such as communication, negotiation, and empathy. We learn to address shopkeepers as "Uncle," "Aunty," "Brother," "Sister," "Grandma," or "Grandpa," fostering a sense of community and respect. These interactions also help us develop our calculation skills, as we learn to negotiate prices, calculate change, and manage our money wisely.

 

The Dangers of dependance on Technology
In today's digital age, it's essential to strike a balance between technology and traditional practices. While technology has its benefits, over-reliance on it can lead to a decline in essential life skills, such as calculation, communication, and social interaction. The next generation is at risk of losing these skills, as they rely solely on technology to manage their daily lives. It's crucial for parents and educators to emphasize the importance of traditional practices, such as visiting markets, cooking, and engaging in outdoor activities, to ensure a well-rounded upbringing.

TIME = MONEY
EITHER YOU MAKE IT (OR) YOU LOSE IT
THE HUNT OF MANKIND FOR THE MAN MADE MONEY

SOLUTION: take back control of your Time and Money
It's time to take back control of our time and attention. Here are some tips and tricks to help you cut down on time theft and make the most of your time and money:


1. Uninstall unnecessary apps: Remove apps that consume your time and attention, and replace them with productivity tools that help you stay focused.
YouTube - Disable your watch history and recommendations to change your homepage to a black screen. Unsubscribe from unnecessary channels to reduce time spent on topics you're not interested in exploring.

Facebook - Utilize it as a personal timeline, manage your friends list by adding and removing friends to maintain a small, close-knit community.

LinkedIn - Profiles of influential individuals who advocate for society are often managed by marketing teams to enhance their popularity and personal brand visibility. Many individuals may portray themselves as kind, brave, and unique, but their actions may not always align with their projected image. Some users openly try to please higher-ranking officials to boost their chances of being hired. Avoid such profiles and focus on showcasing your true professional and personal self. In a sea of pretense, authenticity always stands out.

Time spent wasting is not wasted time. Before the era of online streaming apps, the anticipation of downloading and streaming a movie in 720p provided a sense of satisfaction. However, despite having instant access to content nowadays, the true joy of consuming that content may be missing. Have you noticed this difference?

Streamline your app usage by focusing on one or two apps that truly bring you enjoyment, whether they are games, shopping apps, or online streaming services. With the multitude of choices and reviews out there, having too many options can prevent you from fully enjoying your favorite.


2. Buy wholesale and traditional:
Opt for traditional shopping experiences, such as visiting local markets, to save money and improve the quality of your time. By buying wholesale, you can avoid unnecessary expenses such as GST, packaging charges, handling charges, and delivery fees.
Cook from Scratch: Ready-made meals at the supermarket are quick and easy, but they can get expensive. Cooking from scratch is not only cheaper but also often healthier, with fewer additives and preservatives. When you cook from scratch, you tend to use the same basic ingredients repeatedly. Buying these ingredients in bulk can save you more. Plus, there are often leftovers that can be used for additional meals.

Plan Meals in Advance: Planning your meals in advance helps you avoid impulse purchases and reduces food waste. Make a weekly meal plan, create a shopping list, and stick to it. When you know exactly what you need, you’re less likely to overspend.

Buy Unbranded Products / Stores: Opt for unbranded or store-brand products at the supermarket. They are cheaper and good in quality. You and me should not be a prey for the packaging.

Comparison Shop: Before heading to the grocery store, compare prices among different retailers. Prices for individual products can vary significantly, so switching to the store that charges the least for your staples can save you money.

Avoid Shops: It is recommended to avoid exclusive stores like Nilgiris, Pala mudir chollai, Farm Fresh, Tender Cuts, Lucious, and others, which are targeted towards the elite. This enables individuals to bypass the crowds and save time, especially those considered part of the upper middle class. The truly affluent usually have household help who take care of all their grocery and meat purchases from nearby stores. Hence, it is the middle class who are often ensnared in the realities of daily life.


3. Cut down screen time: Establish screen-free zones and times, such as during meals or before bed, to promote mindful living.
Remember the days when our grandparents or parents would head to the office promptly at 9:30 a.m. and wrap up work by 6 or 6:30 p.m.? They’d then spend the entire evening with family, enjoying peace and relaxation. Contrast that with today’s work culture: we start our day with work as soon as we wake up and end it with work before going to bed.

Several positive aspects of not owning a mobile device include,


Dedicating time to reading books,
Using writing as a means to express your thoughts and enhance your creativity,
Experiment AI with the resources you have,
Engaging in conversations with the elderly to absorb their wisdom, and interacting with the younger generation to understand the prevailing trends and beliefs.
This approach is beneficial for many, except salespeople like me who need to be available for customers or stakeholders 24/7.

Remember, finding a healthy balance between work and personal life is essential for overall well-being. 😊


4. Value nearby things: Prioritize relationships and experiences over material possessions and virtual connections
Appreciate the family and friends who stand by you through all stages of life. Give importance to this strong bond instead of building relationship with unknown people via internet. Staying connected and extending the connection via community is important, opportunity cost is everywhere - you decide which one to prioritize / manage time accordingly.

Your job, title, and social standing are all temporary, bestowed upon you by fate and circumstance. Continue to spread kindness to those around you, pay attention to the little details, and the bigger picture will fall into place.

If you cannot accomplish grand gestures like aiding fellow humans, focus on small acts like feeding a stray dog or providing water for birds. Your actions influence others and inspire change.


5. Save and invest: Save money by avoiding unnecessary expenses, and invest
By depositing Rs. 5,000 saved from grocery, entertainment and altering monthly subscriptions - into a PPF account, you are able to make approximately Rs. 12 lakhs in 15 years with an interest rate of 7-8% annually. Alternatively, you may opt to invest in a ULIP, which provides tax-free compounded market returns of 12-15% and offers the flexibility to make withdrawals as early as the end of the 5th year and Mutual funds at 3rd year.

This way, any money saved through discounts or offers can be stored in surplus accounts like ULIP, PPF, and Mutual Funds, allowing the saved funds to compound and grow without being touched.


ULIP offers an annual investment of 2.5 Lakhs.
PPF allows for an annual investment of 1.5 Lakhs.
Traditional plans allows an annual investment of 5 Lakhs.
Fixed Deposit has a lock-in period of 5 years.
Mutual Funds (ELSS) have a lock-in period of 3 years.
All these instruments mentioned above provides tax-free returns - learn and diversify accordingly. Seek a professional help to choose the investment option best suits your needs and goals.

A Quote to Live By
"Use technology to the limit you want to learn and grow, but don't let technology use you to its fullest. Remember, time is money, and it's time to take back control." - RajaRathinam S K "Making Finance Easy" breaking our subconscious habits towards building wealth & wealthy habits.
By implementing these solutions, you can break free from the cycle of time theft and create a better world for yourself and future generations. It's time to cherish the beauty of life, nurture meaningful relationships, and make the most of the time we have.`,
        image: "https://media.licdn.com/dms/image/v2/D5612AQGHRS3LybrDeg/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1722253984030?e=2147483647&v=beta&t=magBbAjMesRMjP3VdBf9Y0Gp1HXvrf8FcX48ARl7jSs",
        author: "RajaRathinam S K",
        date: "July 29, 2024"
      },
  ];
  
  export default blogs;
  