import React from 'react';
import FamilyForm from '../components/pageComponents/detailsPage/familyForm';

function Details() {
  return (
    <div>
      <FamilyForm/>
    </div>
  );
}

export default Details;