import React from 'react'
import AdminPanel from '../components/pageComponents/adminPage/adminPannel'

export default function Dashboard() {
	return (
		<>
			<AdminPanel />
		</>
	)
}
